<template>
  <div class="p-d-flex p-jc-center">
    <div class="p-d-flex p-flex-column">
      <div class="p-mr-2">
        <h1>{{ title }}</h1>
      </div>
      <div class="p-mr-2">
        <div class="p-d-flex p-jc-center">
          {{ currentYear }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      title: "Qinuau",
      currentYear: ''
    }
  },
  beforeMount: function () {
    this.$data.currentYear = (new Date).getFullYear()
  }
}
</script>
